
import { reactive, onMounted, computed, ref } from 'vue';
import { useStore } from 'vuex';
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { Actions } from "@/store/enums/LotAssembleComponentsEnums.ts";
import { Actions as ProductActions } from "@/store/enums/ProductEnums.ts";
import Table from '@/components/custom/Table.vue';
import LotsToBeUsed from '@/views/lots/assemble_components/LotsToBeUsed.vue'
import CreateAssemblyModal from '@/views/lots/assemble_components/CreateAssemblyModal.vue'
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import moment from 'moment'
export default {
  name : 'AssembleComponents',
  components : {
    Table,
    LotsToBeUsed,
    CreateAssemblyModal
  },
  setup(){
    const store = useStore();

    const searchEnabled = reactive({
      value : false
    });

    const submitButton = ref<HTMLElement | null>(null);

    onMounted(() => {

      store.dispatch(ProductActions.GET_ALL_COMPONENTS,{type : 'composite'})

      setCurrentPageBreadcrumbs("Lots",[
        {title : "Assemble Components",to : "/lots/assemble-components"}
      ]);
    });

    const actions = computed(() => {
      return Actions;
    })

    const additionalFilters = reactive({
      composite_id : null,
      quantity : 1,
      orderDate : moment(new Date()).format('YYYY-MM-DD')
    })

    const detectedChange = (func) => {

      searchEnabled.value = false;

      if(additionalFilters.composite_id != null && additionalFilters.quantity != null && Number(additionalFilters.quantity) > 0 && additionalFilters.orderDate != null && additionalFilters.orderDate.trim() != ""){
        searchEnabled.value = true;
      }

      setTimeout(() => {
        func();
      },100)

    }

    const submitForm = () => {

      submitButton.value?.setAttribute("data-kt-indicator", "on");

      store.dispatch(Actions.VALIDATE_ASSEMBLY_CREATE,
        {
          keyword : "",
          limit : 100,
          searchable : {name : false, code : false, required : false, inStock : false, stockDiff : false},
          sort : {column : "inStock", type : 'asc'},
          additionalFilters : additionalFilters

        }).then((r) => {

          submitButton.value?.removeAttribute("data-kt-indicator");

          if(r.success){

            const createAssemblyModal = document.getElementById('createAssemblyModalBtn') as HTMLElement;

            createAssemblyModal.click();

          }else{

            Swal.fire({
              text: r.message,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Close",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger"
              }
            });

          }

      })
    }

    const columns = [
      {name : 'code', title : 'Product Code', sortable : true, sort : null, searchable : false},
      {name : 'name', title : 'Product Name', sortable : true, sort : null, searchable : false},
      {name : 'required', title : 'Required', sortable : true, sort : null, searchable : false},
      {name : 'inStock', title : 'In Stock', sortable : true, sort : 'desc', searchable : false},
      {name : 'stockDiff', title : 'Surplus (Shortfall)',sortable : false, sort : null, searchable : false}
    ]

    const subtract = (a,b) => {
      return Number(a) - Number(b)
    }

    return {
      columns,
      actions,
      searchEnabled,
      additionalFilters,
      store,
      detectedChange,
      submitButton,
      submitForm,
      subtract
    }

  }
}
