<template>
  <div class="d-inline">
  <!-- Button trigger modal -->
  <button type="submit" class="btn btn-secondary fw-bolder me-3 my-2" data-bs-toggle="modal" data-bs-target="#exampleModal" :disabled="props.disabled">Review Lots To Be Used</button>
  <!-- Modal -->
  <div class="modal fade" id="exampleModal" tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Review Lots to be used</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">

          <Table :columns="columns" ref="table" :action="actions.SEARCH_LOTS_TO_BE_USED" :searchEnabled="false" :showPerPageEnabled="false" :additionalFilters="props.additionalFilters"/>

        </div>

      </div>
    </div>
  </div>
</div>
</template>

<script lang="ts">
import { Actions } from "@/store/enums/LotAssembleComponentsEnums.ts";

import { useStore } from 'vuex'
import { ref, reactive, computed } from 'vue';
import Table from '@/components/custom/Table.vue';
 export default {
   name : 'LotsToBeUsed',
   props : ["disabled","additionalFilters"],
   components : {
     Table
   },
   setup(props){

     const table = ref(null) as any;

     const store = useStore();

     const actions = computed(() => {
       return Actions;
     })

     const columns = [
       {name : 'productCode', title : 'Product Code', sortable : false, sort : null, searchable : false},
       {name : 'productName', title : 'Product Name', sortable : false, sort : null, searchable : false},
       {name : 'lotNumber', title : 'Lot Number', sortable : false, sort : null, searchable : false},
       {name : 'quantity', title : 'Quantity', sortable : false, sort : null, searchable : false},
     ]

     return {
       props,
       store,
       columns,
       actions,
       table
     }
   }
 }
</script>
