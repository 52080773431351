<template>
  <div>

    <div class="card mb-5 mb-xl-10" id="kt_profile_details_view">

      <div class="card-header cursor-pointer">

        <div class="card-title m-0">
          <h3 class="fw-bolder m-0">Assemble Components</h3>
        </div>

      </div>
      <div class="card-body p-7">

        <Table :columns="columns" ref="table" :action="actions.SEARCH_LOT_ASSEMBLY_COMPONENTS" :searchEnabled="false" :showPerPageEnabled="false" :additionalFilters="additionalFilters" :searchApiEnabled="searchEnabled.value === true">


          <template v-slot:extra="slotProps">

            <div class="row mb-3">
              <div class="col-12 mb-3 col-lg-4 mb-lg-0">
                <label class="col-form-label fw-bold fs-6">Composite</label>
                <select class="form-select" v-model="additionalFilters.composite_id" @change="detectedChange(slotProps.search)">
                  <option value="null" selected disabled>Select Composite</option>
                  <option :value="item.id" v-for="(item,key) in store.getters.myAllComponents" :key="key">{{item.name}}</option>
                </select>
              </div>

              <div class="col-12 mb-3 col-lg-4 mb-lg-0">
                <label class="col-form-label fw-bold fs-6">Quantity</label>
                <input type="number" class="form-control" v-model="additionalFilters.quantity" @input="detectedChange(slotProps.search)">
              </div>

              <div class="col-12 mb-3 col-lg-4 mb-lg-0">
                <label class="col-form-label fw-bold fs-6">Order Date</label>
                <input type="date" class="form-control" v-model="additionalFilters.orderDate" @change="detectedChange(slotProps.search)">
              </div>
            </div>

          </template>


          <template v-slot:stockDiff="slotProps">
            <div :class="{'text-danger' : subtract(slotProps.row.inStock,additionalFilters.quantity) < 0, 'text-success' : subtract(slotProps.row.inStock,additionalFilters.quantity) >= 0}">
              {{subtract(slotProps.row.inStock,additionalFilters.quantity)}}
            </div>
          </template>

        </Table>

      </div>

      <!--end::Card body-->
      <div class="card-footer cursor-pointer">
        <!--begin::Card title-->
        <div class="text-end m-0">

          <LotsToBeUsed :disabled="searchEnabled.value === false" :additionalFilters="additionalFilters"/>

          <button
            :disabled="searchEnabled.value === false"
            type="submit"
            ref="submitButton"
            class="btn btn-primary fw-bolder me-0 my-2"
            @click="submitForm"
          >
            <span class="indicator-label">
              Create this Assembly
            </span>

            <span class="indicator-progress">
              Please wait...
              <span
                class="spinner-border spinner-border-sm align-middle ms-2"
              ></span>
            </span>
          </button>

        </div>
        <!--end::Card title-->
      </div>

      <CreateAssemblyModal :orderDate="additionalFilters.orderDate" :quantity="additionalFilters.quantity" :composite_id="additionalFilters.composite_id"/>

    </div>
  </div>
</template>
<script lang="ts">
import { reactive, onMounted, computed, ref } from 'vue';
import { useStore } from 'vuex';
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { Actions } from "@/store/enums/LotAssembleComponentsEnums.ts";
import { Actions as ProductActions } from "@/store/enums/ProductEnums.ts";
import Table from '@/components/custom/Table.vue';
import LotsToBeUsed from '@/views/lots/assemble_components/LotsToBeUsed.vue'
import CreateAssemblyModal from '@/views/lots/assemble_components/CreateAssemblyModal.vue'
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import moment from 'moment'
export default {
  name : 'AssembleComponents',
  components : {
    Table,
    LotsToBeUsed,
    CreateAssemblyModal
  },
  setup(){
    const store = useStore();

    const searchEnabled = reactive({
      value : false
    });

    const submitButton = ref<HTMLElement | null>(null);

    onMounted(() => {

      store.dispatch(ProductActions.GET_ALL_COMPONENTS,{type : 'composite'})

      setCurrentPageBreadcrumbs("Lots",[
        {title : "Assemble Components",to : "/lots/assemble-components"}
      ]);
    });

    const actions = computed(() => {
      return Actions;
    })

    const additionalFilters = reactive({
      composite_id : null,
      quantity : 1,
      orderDate : moment(new Date()).format('YYYY-MM-DD')
    })

    const detectedChange = (func) => {

      searchEnabled.value = false;

      if(additionalFilters.composite_id != null && additionalFilters.quantity != null && Number(additionalFilters.quantity) > 0 && additionalFilters.orderDate != null && additionalFilters.orderDate.trim() != ""){
        searchEnabled.value = true;
      }

      setTimeout(() => {
        func();
      },100)

    }

    const submitForm = () => {

      submitButton.value?.setAttribute("data-kt-indicator", "on");

      store.dispatch(Actions.VALIDATE_ASSEMBLY_CREATE,
        {
          keyword : "",
          limit : 100,
          searchable : {name : false, code : false, required : false, inStock : false, stockDiff : false},
          sort : {column : "inStock", type : 'asc'},
          additionalFilters : additionalFilters

        }).then((r) => {

          submitButton.value?.removeAttribute("data-kt-indicator");

          if(r.success){

            const createAssemblyModal = document.getElementById('createAssemblyModalBtn') as HTMLElement;

            createAssemblyModal.click();

          }else{

            Swal.fire({
              text: r.message,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Close",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger"
              }
            });

          }

      })
    }

    const columns = [
      {name : 'code', title : 'Product Code', sortable : true, sort : null, searchable : false},
      {name : 'name', title : 'Product Name', sortable : true, sort : null, searchable : false},
      {name : 'required', title : 'Required', sortable : true, sort : null, searchable : false},
      {name : 'inStock', title : 'In Stock', sortable : true, sort : 'desc', searchable : false},
      {name : 'stockDiff', title : 'Surplus (Shortfall)',sortable : false, sort : null, searchable : false}
    ]

    const subtract = (a,b) => {
      return Number(a) - Number(b)
    }

    return {
      columns,
      actions,
      searchEnabled,
      additionalFilters,
      store,
      detectedChange,
      submitButton,
      submitForm,
      subtract
    }

  }
}
</script>
