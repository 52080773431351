<template>
  <div>
    <button type="button" class="btn btn-success btn-sm" data-bs-toggle="modal" data-bs-target="#createAssemblyModal" v-show="false" id="createAssemblyModalBtn"></button>

    <div class="modal fade" id="createAssemblyModal" tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false" aria-hidden="true">

      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Create Assembly</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">

            <div class="row mb-3" v-show="success">
              <div class="col-12">
                <div class="alert alert-success" role="alert">
                  Successfully created assembly!
                </div>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-12">
                <label class="col-form-label required fw-bold fs-6">Order Date</label>
                <input type="date" class="form-control" v-model="formData.orderDate" :disabled="true">
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-12">
                <label class="col-form-label required fw-bold fs-6">Quantity</label>
                <input type="number" class="form-control" v-model="formData.quantity" :disabled="true">
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-12">
                <label class="col-form-label required fw-bold fs-6">Labels</label>
                <input type="number" class="form-control" :class="{'is-invalid' : errors.value && errors.value.labels}" v-model="formData.labels">
                <span class="text-danger" v-if="errors.value && errors.value.labels">{{errors.value && errors.value.labels[0]}}</span>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-12">
                <label class="col-form-label fw-bold fs-6">Sealing Machine</label>
                <input type="text" class="form-control" :class="{'is-invalid' : errors.value && errors.value.sealingMachine}" v-model="formData.sealingMachine">
                <span class="text-danger" v-if="errors.value && errors.value.sealingMachine">{{errors.value && errors.value.sealingMachine[0]}}</span>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-12">
                <label class="col-form-label required fw-bold fs-6">Lot Number</label>
                <input type="text" class="form-control" :class="{'is-invalid' : errors.value && errors.value.lotNumber}" v-model="formData.lotNumber">
                <span class="text-danger" v-if="errors.value && errors.value.lotNumber">{{errors.value && errors.value.lotNumber[0]}}</span>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-12">
                <label class="col-form-label fw-bold fs-6">Allow Duplicate Lot#</label>
                <div class="form-check form-switch">
                  <input class="form-check-input" type="checkbox" v-model="formData.allowDuplicateLot" id="flexSwitchCheckDefault">
                  <label class="form-check-label" for="flexSwitchCheckDefault"></label>
                </div>
                <span class="text-danger" v-if="errors.value && errors.value.allowDuplicateLot">{{errors.value && errors.value.allowDuplicateLot[0]}}</span>
              </div>
            </div>

          </div>

          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" id="close-modal">Close</button>
            <button
              type="submit"
              id="createAssemblySubmitBtn"
              class="btn btn-primary fw-bolder"
              @click="submitForm();"
            >
              <span class="indicator-label">
                Save
              </span>

              <span class="indicator-progress">
                Please wait...
                <span
                  class="spinner-border spinner-border-sm align-middle ms-2"
                ></span>
              </span>
            </button>
          </div>

        </div>
      </div>

    </div>
  </div>
</template>
<script lang="ts">
import { useStore } from 'vuex'
import { ref, reactive, onMounted, watch } from 'vue'
import { useRouter } from 'vue-router';
import { Actions } from "@/store/enums/LotAssembleComponentsEnums.ts";
export default {
  name : 'CreateAssemblyModal',
  props : ["orderDate", "quantity", "composite_id"],
  setup(props, { emit }){
    const store = useStore();
    const router = useRouter();

    const success = ref(false);

    const errors = reactive({
      value : {}
    });

    const formData = reactive({
      composite_id : props.composite_id,
      orderDate : props.orderDate,
      quantity : props.quantity,
      labels : 5,
      sealingMachine : '',
      lotNumber : '',
      allowDuplicateLot : false
    })

    watch(() => props.orderDate, (newValue) => {
      formData.orderDate = newValue
    });
    watch(() => props.quantity, (newValue) => {
      formData.quantity = newValue
    });
    watch(() => props.composite_id, (newValue) => {
      formData.composite_id = newValue
    });

    onMounted(() => {

      const myModalEl = document.getElementById('createAssemblyModal') as any
      myModalEl.addEventListener('hidden.bs.modal', function () {
        success.value = false;
        errors.value = [];
        formData.composite_id = props.composite_id;
        formData.orderDate = props.orderDate;
        formData.quantity = props.quantity;
        formData.labels = 5;
        formData.sealingMachine = '';
        formData.lotNumber = '';
        formData.allowDuplicateLot = false;
      })
    })

    const submitForm = () => {
      const submitButton = ref<HTMLElement | null>(document.getElementById('createAssemblySubmitBtn') as HTMLElement);

      submitButton.value?.setAttribute("data-kt-indicator", "on");

      store.dispatch(Actions.CREATE_ASSEMBLY,formData).then((r) => {
        errors.value = [];
        success.value = true;
        setTimeout(function(){

          const closeModal = document.getElementById('close-modal') as HTMLElement;
          closeModal.click();
          emit('input');
          submitButton.value?.removeAttribute("data-kt-indicator");
          router.push('/lots/composite/edit/'+r.id)
        },1000)
      }).catch((response) => {
        setTimeout(function(){
          const input = document.getElementsByClassName('is-invalid')[0] as HTMLElement
          input.focus();
        },100)

        errors.value = response.data.errors;

        submitButton.value?.removeAttribute("data-kt-indicator");

      })
    }

    return {
      store,
      success,
      errors,
      formData,
      submitForm
    }
  }
}
</script>
