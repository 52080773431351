
import { useStore } from 'vuex'
import { ref, reactive, onMounted, watch } from 'vue'
import { useRouter } from 'vue-router';
import { Actions } from "@/store/enums/LotAssembleComponentsEnums.ts";
export default {
  name : 'CreateAssemblyModal',
  props : ["orderDate", "quantity", "composite_id"],
  setup(props, { emit }){
    const store = useStore();
    const router = useRouter();

    const success = ref(false);

    const errors = reactive({
      value : {}
    });

    const formData = reactive({
      composite_id : props.composite_id,
      orderDate : props.orderDate,
      quantity : props.quantity,
      labels : 5,
      sealingMachine : '',
      lotNumber : '',
      allowDuplicateLot : false
    })

    watch(() => props.orderDate, (newValue) => {
      formData.orderDate = newValue
    });
    watch(() => props.quantity, (newValue) => {
      formData.quantity = newValue
    });
    watch(() => props.composite_id, (newValue) => {
      formData.composite_id = newValue
    });

    onMounted(() => {

      const myModalEl = document.getElementById('createAssemblyModal') as any
      myModalEl.addEventListener('hidden.bs.modal', function () {
        success.value = false;
        errors.value = [];
        formData.composite_id = props.composite_id;
        formData.orderDate = props.orderDate;
        formData.quantity = props.quantity;
        formData.labels = 5;
        formData.sealingMachine = '';
        formData.lotNumber = '';
        formData.allowDuplicateLot = false;
      })
    })

    const submitForm = () => {
      const submitButton = ref<HTMLElement | null>(document.getElementById('createAssemblySubmitBtn') as HTMLElement);

      submitButton.value?.setAttribute("data-kt-indicator", "on");

      store.dispatch(Actions.CREATE_ASSEMBLY,formData).then((r) => {
        errors.value = [];
        success.value = true;
        setTimeout(function(){

          const closeModal = document.getElementById('close-modal') as HTMLElement;
          closeModal.click();
          emit('input');
          submitButton.value?.removeAttribute("data-kt-indicator");
          router.push('/lots/composite/edit/'+r.id)
        },1000)
      }).catch((response) => {
        setTimeout(function(){
          const input = document.getElementsByClassName('is-invalid')[0] as HTMLElement
          input.focus();
        },100)

        errors.value = response.data.errors;

        submitButton.value?.removeAttribute("data-kt-indicator");

      })
    }

    return {
      store,
      success,
      errors,
      formData,
      submitForm
    }
  }
}
