
import { Actions } from "@/store/enums/LotAssembleComponentsEnums.ts";

import { useStore } from 'vuex'
import { ref, reactive, computed } from 'vue';
import Table from '@/components/custom/Table.vue';
 export default {
   name : 'LotsToBeUsed',
   props : ["disabled","additionalFilters"],
   components : {
     Table
   },
   setup(props){

     const table = ref(null) as any;

     const store = useStore();

     const actions = computed(() => {
       return Actions;
     })

     const columns = [
       {name : 'productCode', title : 'Product Code', sortable : false, sort : null, searchable : false},
       {name : 'productName', title : 'Product Name', sortable : false, sort : null, searchable : false},
       {name : 'lotNumber', title : 'Lot Number', sortable : false, sort : null, searchable : false},
       {name : 'quantity', title : 'Quantity', sortable : false, sort : null, searchable : false},
     ]

     return {
       props,
       store,
       columns,
       actions,
       table
     }
   }
 }
